.main-page--banner {
  background: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
    url('../../assets/mainPageBanner.png') center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px 62px 20px;
  margin-bottom: 24px;

  &-desc {
    margin: auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    &--title {
      color: #fff;
      font-size: 60px;
      font-family: Gilroy-Bold, sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 6.3px;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 40px;

      span {
        color: #f29e06;
      }
    }

    &--text {
      color: #cbc8c8;
      text-align: center;
      font-size: 24px;
      font-family: Gilroy-SemiBold, sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 2.52px;
      max-width: 806px;
      margin-bottom: 130px;
    }

    &--buttons {
      display: flex;
      align-items: center;
      gap: 50px;

      &--btn {
        color: #f29e06;
        border: 2px solid #fff;
        background: transparent;
        min-width: 400px;
        padding: 25px;
        outline: none;
        text-align: center;
        font-size: 24px;
        font-family: Gilroy-SemiBold, sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 2.52px;
        text-transform: uppercase;
        transition: all 0.3s;

        &:hover {
          background: #f29e06;
          color: #fff;
          border: 2px solid #f29e06;
        }
      }
    }
  }

  &-bottom {
    display: flex;
    align-items: center;
    gap: 50px;

    &--item {
      display: flex;
      gap: 13px;
      align-items: center;

      &_center {
        padding: 0 50px;
        border-left: 1px solid #ffffff;
        border-right: 1px solid #ffffff;
      }

      &-desc {
        display: flex;
        flex-direction: column;
        gap: 5px;

        &-number {
          color: #fff;
          text-align: left;
          font-size: 24px;
          font-family: Gilroy-SemiBold, sans-serif;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 2.52px;
          text-transform: uppercase;
        }

        &-text {
          color: #fff;
          text-align: center;
          font-size: 14px;
          font-family: Gilroy-Regular, sans-serif;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 1.47px;
        }
      }
    }
  }
}

.main-page--content {
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-bottom: 178px;

  &-info {
    display: flex;
    align-items: center;
    margin: 0 10%;
    justify-content: space-between;
    gap: 10px;

    &--desc {
      max-width: 631px;

      &--btn {
        min-width: 200px;
        padding: 10px;
        background: transparent;
        outline: none;
        margin-top: 40px;
        border: 1px solid #f29e06;

        color: #f29e06;
        text-align: center;
        font-family: Gilroy-SemiBold, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.68px;
        text-transform: uppercase;
        transition: all 0.3s;

        &:hover {
          color: #ffffff;
          background: #f29e06;
          border: 1px solid #ffffff;
        }
      }
    }
  }
}

.main-page--bottom-slider {
  margin-left: 10%;
  margin-bottom: 10%;
  overflow-x: hidden;

  &-desc {
    max-width: 631px;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 1500px) {
  .main-page--content {
    &-info {
      &--desc {
        max-width: 631px;

        &-img {
          max-width: 500px;

          img {
            max-width: 500px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .main-page--content {
    margin-bottom: 100px;

    &-info {
      gap: 20px;
      margin: 0 5%;
    }
  }

  .main-page--banner {
    padding-bottom: 20px;

    &-desc {
      &--title {
        font-size: 60px * 0.7;
      }

      &--text {
        font-size: 24px * 0.7;
        margin-bottom: 100px;
      }

      &--buttons {
        gap: 30px;

        &--btn {
          min-width: 200px;
          font-size: 24px * 0.7;
        }
      }
    }

    &-bottom {
      gap: 30px;

      &--item {
        &_center {
          padding: 0 30px;
        }

        &-desc {
          &-number {
            font-size: 24px * 0.7;
          }

          &-text {
            font-size: 14px * 0.7;
          }
        }
      }
    }
  }

  .main-page--bottom-slider {
    margin-left: 5%;
  }
}

@media screen and (max-width: 800px) {
  .main-page--banner {
    padding-bottom: 20px;

    &-desc {
      &--title {
        font-size: 60px * 0.5;
      }

      &--text {
        font-size: 24px * 0.5;
        margin-bottom: 100px;
      }

      &--buttons {
        gap: 30px;

        &--btn {
          min-width: 200px;
          padding: 15px;
          font-size: 24px * 0.5;
        }
      }
    }

    &-bottom {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 30px;

      &--item {
        &_center {
          padding: 0;
          border: none;
        }

        &-desc {
          &-number {
            font-size: 24px * 0.5;
          }

          &-text {
            font-size: 14px * 0.5;
          }
        }
      }
    }
  }

  .main-page--content {
    margin-bottom: 70px;

    &-info {
      gap: 20px;

      &--desc {
        max-width: 631px;

        &--btn {
          min-width: 150px;
          padding: 5px;
          background: transparent;
          outline: none;
          margin-top: 20px;
          border: 1px solid #f29e06;

          color: #f29e06;
          text-align: center;
          font-family: Gilroy-SemiBold, sans-serif;
          font-size: 16px * 0.5;
        }

        &-img {
          max-width: 250px;

          img {
            max-width: 250px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .main-page--banner {
    padding: 0 15px 15px 15px;

    &-desc {
      &--title {
        font-size: 60px * 0.3;
      }

      &--text {
        font-size: 24px * 0.4;
        margin-bottom: 50px;
      }

      &--buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;

        &--btn {
          min-width: 200px;
          padding: 15px;
          font-size: 24px * 0.5;
        }
      }
    }

    &-bottom {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 20px;
    }
  }

  .main-page--content {
    &-info {
      gap: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &_top {
        flex-direction: column-reverse;
      }

      &--desc {
        max-width: 631px;

        &--btn {
          min-width: 150px;
          padding: 5px;
          background: transparent;
          outline: none;
          margin-top: 20px;
          border: 1px solid #f29e06;

          color: #f29e06;
          text-align: center;
          font-family: Gilroy-SemiBold, sans-serif;
          font-size: 16px * 0.5;
        }

        &-img {
          max-width: 290px;

          img {
            max-width: 290px;
          }
        }
      }
    }
  }
  .main-page--bottom-slider {
    margin-left: 5%;
    margin-right: 5%;
  }
}

.product-item-slider {
  max-width: 600px;
}

.product-item-slider--img {
  width: 600px;
  height: 600px;
}

@media screen and (max-width: 1400px) {
  .product-item-slider {
    max-width: 400px;
  }

  .product-item-slider--img {
    width: 400px;
    height: 400px;
  }
}

@media screen and (max-width: 500px) {
  .product-item-slider {
    max-width: 300px;
  }

  .product-item-slider--img {
    width: 300px;
    height: 300px;
  }
}

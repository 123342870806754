.last-news-slider {
  width: 100%;
  overflow-x: hidden;

  .react-slideshow-container + ul.indicators {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    margin-top: 55px;
    gap: 10px;
  }
}

.indicator {
  cursor: pointer;
  padding: 15px;
  text-align: center;
  margin: 0;
  border-radius: 50%;
  width: 50px;
  height: 50px;

  color: #000000;
  font-family: Gilroy-Medium, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.indicator.active {
  color: #fff;
  background: #f29e06;
}

@media screen and (max-width: 500px) {
  .react-slideshow-container + ul.indicators {
    margin-top: 30px;
    gap: 5px;
  }
  .indicator {
    padding: 15px * 0.8;
    width: 50px * 0.8;
    height: 50px * 0.8;
    font-size: 18px * 0.8;
  }
}

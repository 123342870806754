.privacy-policy {
  margin: 12vh auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  word-wrap: break-word;
  padding: 2rem;

  h1 {
    font-family: Gilroy-Bold, sans-serif;
    margin-bottom: 2rem;
  }
  p {
    font-family: Gilroy-Regular, sans-serif;
  }
}

.news-card-wrapper {
  display: flex;
  padding: 0 11px 19px 25px;
  width: 400px;
  height: 500px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &_scale {
    &:hover {
      transform: scale(1.1);
    }
  }

  .news-card--desc {
    display: flex;
    gap: 5px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;

    &-date {
      display: flex;
      gap: 5px;
      align-items: center;

      color: #d0d0d0;
      font-family: Gilroy-Medium, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 157.3%; /* 18.876px */
      letter-spacing: 0.9px;
    }

    &-title {
      color: #fff;
      font-family: Gilroy-SemiBold, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 157.3%; /* 25.168px */
      letter-spacing: 1.2px;
    }

    &-btn {
      background: none;
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      gap: 10px;

      color: #f29e06;
      font-family: Gilroy-Regular, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 157.3%; /* 22.022px */
      letter-spacing: 1.05px;
      transition: all 0.3s;

      svg {
        fill: #f29e06;
        transition: all 0.3s;
      }

      &:hover {
        color: #d0d0d0;

        svg {
          fill: #d0d0d0;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .news-card-wrapper {
    display: flex;
    padding: 0 11px 19px 25px;
    width: 300px;
    height: 400px;
  }
}

@media screen and (max-width: 800px) {
  .news-card-wrapper {
    display: flex;
    padding: 0 10px 10px 15px;
    width: 250px;
    height: 350px;

    .news-card--desc {
      &-date {
        font-size: 12px * 0.8;
      }

      &-title {
        font-size: 16px * 0.8;
      }

      &-btn {
        gap: 5px;

        color: #f29e06;
        font-family: Gilroy-Regular, sans-serif;
        font-size: 14px * 0.8;
        font-style: normal;
        font-weight: 400;
        line-height: 157.3%; /* 22.022px */
        letter-spacing: 1.05px;
      }
    }
  }
}

.news-wrapper {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin: 10%;
}

@media screen and (max-width: 1024px) {
  .news-wrapper {
    margin: 15% 5% 5%;
    gap: 30px;
  }
}

@media screen and (max-width: 800px) {
  .news-wrapper {
    margin: 25% 3% 3%;
    gap: 20px;
  }
}

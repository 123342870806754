.news-page {
  background-color: transparentize(#cbc8c8, 0.7);
}

.container {
  font-family: Gilroy-Medium, sans-serif;
  color: #282828;
  letter-spacing: 1.5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-bottom: 24px;
  padding-top: 15vh;
  @media (max-width: 500px) {
    padding-top: 10vh;
  }

  &--date {
    margin: 10px 10px 10px 0;
    color: #7f7f7f;
    display: flex;
    gap: 5px;
    font-style: normal;
    font-weight: 400;
    line-height: 157.3%; /* 18.876px */
    letter-spacing: 0.9px;

    .icon {
      margin-top: 5px;
      height: 19px;
    }
  }

  &--main-news {
    padding: 20px;
    background-color: #ffffff;
    max-width: 70%;

    @media (max-width: 768px) {
      max-width: 95%;
    }

    h1 {
      margin: 10px 10px 10px 0;
      line-height: 37.5px;
      letter-spacing: 1.7px;
    }

    &--text {
      font-size: 18px;
      text-align: left;
      line-height: 157.3%;

      p {
        margin-bottom: 10px;
      }
    }

    &--image {
      height: 470px;
      width: 100%;
      object-fit: cover;

      @media (max-width: 768px) {
        height: 350px;
      }
    }
  }
}

.news-page--loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.all-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;

  .content-place {
    width: 100%;
  }
}

.footer-wrapper {
  background: black;
  color: white;
  padding: 35px 10% 15px 10%;
  width: 100%;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 13px;

  &--column-desc {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 663px;

    &-title {
      color: #f29e06;
      font-size: 22px;
      font-family: Gilroy-Bold, sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 2.31px;
      text-transform: uppercase;
    }

    &-text {
      color: #a7a7a7;
      font-size: 16px;
      font-family: Gilroy-Regular, sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: 157.3%;
      letter-spacing: 1.2px;
    }

    &-buttons {
      display: flex;
      align-items: center;
      gap: 20px;

      &-btn {
        background: none;
        border: none;
        outline: none;

        img {
          svg {
            fill: #f29e06;
          }
        }

        svg {
          fill: #f29e06;
        }

        &:hover {
          svg {
            fill: #fff;
          }
        }
      }
    }
  }

  &--column {
    display: flex;
    flex-direction: column;

    &-title {
      margin-bottom: 20px;
      color: #f29e06;
      font-size: 20px;
      font-family: Gilroy-Bold, sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &-pages-links {
      display: flex;
      flex-direction: column;
      gap: 11px;
      list-style-type: none;
    }

    &-links {
      display: flex;
      flex-direction: column;
      gap: 15px;

      div {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      span {
        font-size: 16px;
        font-family: Gilroy-Regular, sans-serif;
        font-style: normal;
        font-weight: 400;
      }

      &-link {
        text-decoration: none;
        color: #fff;
        font-size: 16px;
        font-family: Gilroy-Regular, sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 0;
        transition: all 0.3s;

        &:hover {
          color: #aaa;
        }
      }
    }
  }
}

.footer-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .policy {
    display: flex;
    gap: 40px;

    &-link {
      color: #aaa;
      font-size: 16px;
      font-family: Gilroy-Regular, sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: 0;
      transition: all 0.3s;

      &:hover {
        color: #ffffff;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .footer-wrapper {
    padding: 35px 50px 15px 50px;
  }
}

@media screen and (max-width: 1024px) {
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 40px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    margin-bottom: 13px;

    &--column-desc {
      display: flex;
      flex-direction: column;
      gap: 15px;
      max-width: 663px;

      &-title {
        font-size: 18px;
      }

      &-text {
        font-size: 14px;
      }

      &-buttons {
        display: flex;
        align-items: center;
        gap: 20px;
      }
    }

    &--column {
      display: flex;
      flex-direction: column;

      &-title {
        margin-bottom: 15px;
        font-size: 16px;
      }

      &-links {
        display: flex;
        flex-direction: column;
        gap: 15px;

        &-link {
          font-size: 12px;
        }
      }
    }
  }

  .footer-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .policy {
      display: flex;
      gap: 40px;

      &-link {
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .footer-content {
    flex-wrap: wrap;
    gap: 40px;
  }
}

@media screen and (max-width: 500px) {
  .footer-wrapper {
    background: black;
    color: white;
    padding: 35px 15px 15px 15px;
    width: 100%;
  }

  .powered-by {
    img {
      width: 100px;
    }
  }
}

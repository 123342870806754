.forum-info {
  max-width: 85%;
  margin: 3vh auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &--btn-error {
    width: 60%;
    display: flex;
    flex-wrap: nowrap;
    gap: 32px;

    button {
      color: #000;
      text-align: center;
      font-family: 'Gilroy-SemiBold', sans-serif;
      font-size: 1.2vw;
      letter-spacing: 1.89px;
      text-transform: uppercase;
      width: 100%;
      padding: 15px 0;
      border: 2px solid #f29e06;
      background-color: #ffffff;
    }

    button:hover {
      background-color: #f29e06;
    }
  }

  &--btn-ask {
    display: flex;
    align-items: center;
    padding: 15px 25px;
    color: #ffffff;
    font-family: 'Gilroy-Black', sans-serif;
    font-size: 1vw;
    text-transform: uppercase;
    background-color: #f29e06;
    border: none;
  }

  &--btn-ask:hover {
    box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.5);
  }

  img {
    margin-right: 15px;
  }
}

.forum-wrapper {
  max-width: 85%;
  margin: 60px auto;

  .forum-item {
    display: flex;
    justify-content: space-between;
    font-family: Gilroy, sans-serif;
    margin-bottom: 1em;
    border-bottom: 1px solid rgba(178, 178, 178, 0.5);
    cursor: pointer;

    h2 {
      font-weight: 400;
      margin-bottom: 0.5em;
      font-family: 'Gilroy-SemiBold', sans-serif;
    }

    .date-energy {
      display: flex;
      align-items: center;
      gap: 1.2em;
      margin-bottom: 1em;
      color: #7d7d7d;

      p {
        display: flex;
        gap: 0.5em;
        align-items: center;
        font-family: 'Gilroy-Light', sans-serif;
      }

      p.energy-type {
        gap: 1rem;
        display: flex;
      }
    }

    .post-number {
      display: flex;
      align-items: center;

      .number {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 5em;

        p {
          color: #f29e06;
          font-size: 1.5vw;
        }

        span {
          color: #7d7d7d;
          font-weight: lighter;
        }
      }
    }
  }
}

.forum-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
  font-family: Gilroy, sans-serif;

  button {
    background: none;
    border: none;
  }

  &--item {
    margin: 0 0.2em;
    padding: 0.3em 0.65em;
    border-radius: 50%;
    cursor: pointer;
  }

  .active {
    background-color: rgba(242, 158, 6, 0.2);
    color: #f29e06;
  }
}

@media (max-width: 800px) {
  .forum-info {
    flex-direction: column;
    gap: 5px;

    &--btn-error {
      flex-direction: column;
      width: 100%;
      gap: 5px;

      button {
        font-size: 1.5vw;
      }
    }

    &--btn-ask {
      justify-content: center;
      font-size: 1.5vw;
    }
  }

  .forum-item {
    h2 {
      font-size: 2.5vw;
    }

    .date-energy p {
      font-size: 1.8vw;
    }
  }
  .forum-wrapper .forum-item .post-number .number {
    p {
      font-size: 2vw;
    }

    span {
      font-size: 1.8vw;
    }
  }
}

@media (max-width: 700px) {
  .forum-info--btn-error button {
    font-size: 2vw;
  }
  .forum-info--btn-ask {
    font-size: 2vw;
  }

  .forum-item .name-date-energy h2 {
    font-size: 2.8vw;
  }

  .forum-wrapper .forum-item .post-number .number {
    margin-right: 2em;
  }
}

@media (max-width: 600px) {
  .forum-info--btn-error button {
    font-size: 2.2vw;
  }
  .forum-info--btn-ask {
    font-size: 2.2vw;
  }

  .forum-wrapper .forum-item {
    flex-direction: column;
    justify-content: space-around;

    .name-date-energy {
      .date-energy {
        justify-content: space-between;
      }
    }

    .post-number {
      display: none;
    }

    h2 {
      font-size: 3.2vw;
    }
  }
}

@media (max-width: 500px) {
  .forum-info--btn-error button {
    font-size: 2.5vw;
  }
  .forum-info--btn-ask {
    font-size: 2.5vw;
  }

  .forum-item .name-date-energy {
    .date-energy p {
      font-size: 2.2vw;
    }

    h2 {
      font-size: 3.5vw;
    }
  }
}

@media (max-width: 400px) {
  .forum-info--btn-error button {
    font-size: 2.7vw;
  }
  .forum-info--btn-ask {
    font-size: 2.7vw;
  }

  .forum-item .name-date-energy {
    .date-energy p {
      font-size: 2.5vw;
    }

    h2 {
      font-size: 4vw;
    }
  }
}

.not-found-product {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 39px;
  max-width: 711px;
  margin: auto;

  &--title {
    color: #000;
    text-align: center;
    font-family: Gilroy-Bold, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 4.2px;
  }

  &--desc {
    color: #545454;
    font-family: Gilroy-Medium, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.89px;
  }
}

.product-card-wrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 280px;

  .product-card--img {
    cursor: pointer;
    position: relative;
    overflow: hidden;

    img {
      width: 280px;
      height: 280px;
    }

    .product-card--btn {
      background: rgba(65, 65, 65, 0.9);
      min-width: 220px;
      border: none;
      outline: none;
      padding: 14px;
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      bottom: -60px;
      transition: all 0.3s;

      color: #fff;
      font-family: Gilroy-SemiBold, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.89px;

      &:hover {
        background: rgb(65, 65, 65);
      }
    }

    &:hover {
      .product-card--btn {
        bottom: 40px;
      }
    }
  }

  .product-card--desc {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &--name {
      color: #040404;
      font-family: Gilroy-Regular, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.89px;
    }

    &--categs {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
    }

    &--categ {
      color: #878787;
      font-family: Gilroy-Regular, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.68px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .product-card-wrapper {
    gap: 20px;
    max-width: 200px;

    .product-card--img {
      img {
        width: 200px;
        height: 200px;
      }

      .product-card--btn {
        min-width: 200px;
      }
    }

    .product-card--desc {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &--name {
        color: #040404;
        font-family: Gilroy-Regular, sans-serif;
        font-size: 18px * 0.7;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.89px;
      }

      &--categ {
        color: #878787;
        font-family: Gilroy-Regular, sans-serif;
        font-size: 16px * 0.7;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.68px;
      }
    }
  }
}

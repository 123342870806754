.header-wrapper {
  position: fixed;
  z-index: 100;
  padding: 0 10% 0 50px;
  width: 100%;
  height: 12vh;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .header-logo {
    width: 167px;
  }
}

.header-wrapper-active {
  background: rgba(0, 0, 0, 0.7) !important;
}

.header-navigation {
  display: flex;
  gap: 55px;
  align-items: center;

  &--link {
    color: #fff;
    font-size: 18px;
    font-family: Gilroy-Medium, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.89px;
    text-transform: uppercase;
    transition: color 0.3s;

    &:hover {
      color: #f29e06;
    }
  }

  &-btn {
    visibility: hidden;
    opacity: 0;
    display: none;
  }
}

.header-navigation a.active {
  color: #f29e06;
}

@media screen and (max-width: 1200px) {
  .header-wrapper {
    padding: 15px 50px;
  }
}

@media screen and (max-width: 1024px) {
  .header-navigation {
    display: flex;
    gap: 55px;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    transition: 1s;
    transform: translateY(-120vh);
    background: black;

    &-responsive {
      transform: none;
    }

    &-btn {
      visibility: visible;
      opacity: 1;
      display: block;
      background: none;
      border: none;
      outline: none;

      svg {
        fill: #ffffff;
        stroke: #ffffff;
      }
    }

    &-close-btn {
      position: absolute;
      top: 25px;
      right: 50px;
    }
  }
}

@media screen and (max-width: 800px) {
  .header-wrapper {
    height: 10vh;
  }
}

@media screen and (max-width: 500px) {
  .header-wrapper {
    padding: 0 15px;
    height: 8vh;

    .header-logo {
      width: 30%;
    }
  }
}

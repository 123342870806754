.contact-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 70%;
  margin: 4em auto;

  @media (max-width: 1000px) {
    max-width: 90%;
    margin: 2em auto;
  }

  .supporting-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;

    h1 {
      color: var(--gray-900, #101828);
      font-family: Gilroy-Bold, sans-serif;
      margin-bottom: 0.5em;
    }

    p {
      color: var(--gray-500, #667085);
      font-family: Gilroy-Regular, sans-serif;
      font-weight: lighter;
      letter-spacing: 0.05em;
      text-align: center;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;

    @media (max-width: 1000px) {
      width: 90%;
    }

    .form-control {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 1em;

      label {
        color: var(--gray-700, #344054);
        font-family: Gilroy-SemiBold, sans-serif;
        font-weight: 500;
        margin-bottom: 0.5em;
      }

      input,
      select {
        color: var(--gray-500, #667085);
        font-family: Gilroy, sans-serif;
        height: 3em;
        border-radius: 8px;
        border: 1px solid var(--gray-300, #d0d5dd);
        box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
        padding: 0 10px;
      }

      input:placeholder-shown {
        text-overflow: ellipsis;
      }

      textarea {
        height: 10em;
        border-radius: 8px;
        border: 1px solid var(--gray-300, #d0d5dd);
        box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
        max-width: 100%;
        padding: 10px;
      }

      input.invalid,
      textarea.invalid {
        border: 1px solid #f29e06;
      }
    }

    .checkbox {
      display: flex;
      color: #344054;
      font-family: Gilroy-Medium, sans-serif;
      margin-bottom: 1.5em;

      &--link {
        color: #344054;
        text-decoration: underline;
      }

      input {
        height: 1.4em;
        width: 1.4em;
        margin-right: 0.5em;
        border: 1px solid #d0d5dd;
      }
    }

    button {
      color: #ffffff;
      font-size: 16px;
      height: 3em;
      background: #f29e06;
      box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
      border: none;
      width: 100%;
      font-family: Gilroy-SemiBold, sans-serif;
    }

    button:disabled {
      background-color: #8c8c8c;
      color: white;
    }
  }
}

.content {
  margin: 10vh 0;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 800px) {
    margin: 10vh 7vw;
    border: none;
  }

  &--content-wrapper {
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
    border-radius: 10px;
    padding: 1.5rem;
    align-items: center;
    width: 30rem;

    @media screen and (max-width: 800px) {
      width: 90vw;
      border: none;
    }

    &--title {
      text-align: center;
      margin-bottom: 1rem;
    }

    .sign-up-form {
      display: flex;
      flex-direction: column;
      min-width: 90%;

      input {
        margin: 0.5rem 0;
        padding: 1rem;
        border-radius: 10px;
        border: 1px solid #b0afaf;
      }

      input:focus {
        outline: none;
      }

      input.invalid {
        border: 1px solid #f29e06;
      }

      input::placeholder {
        text-align: center;
      }

      button {
        margin: 1rem auto;
        padding: 1rem;
        cursor: pointer;
        color: #fff;
        font-size: 1.5rem;
        min-width: 60%;
        background-color: #f29e06;
        border: none;
        border-radius: 10px;
      }

      button:disabled {
        background-color: #8c8c8c;
        color: white;
      }
    }
  }
}

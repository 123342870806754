.comment-container {
  margin: 2rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);

  .comment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--left {
      display: flex;

      &--avatar {
        margin-right: 0.5rem;
      }

      &--name {
        &--full-name {
          font-size: 1.2rem;
          font-family: 'Gilroy-Medium', sans-serif;
          color: #000;
        }

        &--company-name {
          font-size: 0.9rem;
          color: #616161;
          font-family: 'Gilroy-Regular', sans-serif;
        }
      }
    }

    &--right {
      font-size: 0.9rem;
      color: #757575;
      align-self: start;
      font-family: 'Gilroy-Light', sans-serif;
    }
  }

  .comment-body {
    margin: 1.5rem 0;
    color: #4b4b4b;
  }
}

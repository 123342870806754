.post-content {
  max-width: 85%;
  margin: 3vh auto;

  &--loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
  }

  &--header {
    display: flex;
    justify-content: space-between;

    &--left,
    &--right {
      display: flex;
      align-items: center;
      gap: 1rem;

      img {
        display: block;
      }

      &--title {
        font-weight: bold;
        font-size: 1.2rem;
        font-family: 'Gilroy-SemiBold', sans-serif;

        @media screen and (max-width: 500px) {
          font-size: 0.8rem;
        }
      }

      &--container {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
    }

    &--right {
      color: #545454;
      font-family: 'Gilroy-Light', sans-serif;
    }
  }

  &--body {
    padding-bottom: 2rem;
    margin: 1rem 0 2rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    font-family: 'Gilroy-Medium', sans-serif;
  }

  &--input {
    display: flex;
    flex-direction: column;

    textarea {
      height: 10vh;
      padding: 0.7rem;
    }

    textarea:focus {
      border-color: #f29e06;
      outline: none !important;
    }

    &--send-button {
      max-width: 30%;
      margin: 2rem 0;
      padding: 1rem 1.2rem;
      border: none;
      background-color: #f29e06;
      color: #fff;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.7rem;
      font-family: 'Gilroy-Medium', sans-serif;

      @media screen and (max-width: 600px) {
        width: 80%;
        max-width: 80%;
        align-self: center;
      }
    }
  }
}

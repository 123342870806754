.label {
  color: #f29e06;
  font-size: 22px;
  font-family: Gilroy-Medium, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.31px;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 20px;

  .underscore {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 83px;
    height: 2px;
    background: #7f7f7f;
  }
}

.title {
  color: #000;
  font-size: 32px;
  font-family: Gilroy-Bold, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.4px;
  margin-bottom: 5px;
  max-width: 540px;
}

.text {
  color: #636363;
  font-size: 18px;
  font-family: Gilroy-Medium, sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1.35px;
}

@media screen and (max-width: 1100px) {
  .label {
    font-size: 22px * 0.7;
  }

  .title {
    font-size: 32px * 0.7;
  }

  .text {
    font-size: 18px * 0.7;
  }
}

@media screen and (max-width: 800px) {
  .label {
    font-size: 22px * 0.6;
    margin-bottom: 10px;
  }

  .title {
    font-size: 32px * 0.6;
  }

  .text {
    font-size: 18px * 0.7;
  }
}

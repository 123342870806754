$font-bold: 'Gilroy-Bold', sans-serif;
$font-heavy: 'Gilroy-Heavy', sans-serif;
$font-light: 'Gilroy-Light', sans-serif;
$font-medium: 'Gilroy-Medium', sans-serif;
$font-regular: 'Gilroy-Regular', sans-serif;

html * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

body {
  min-height: 100vh;
  min-width: 300px;
  overflow-x: hidden;
  font-family: $font-regular;
}

a {
  text-decoration: none;
  color: #282828;
}

button {
  cursor: pointer;
}

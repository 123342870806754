.forum {
  background-color: #f29e06;

  .forum-header {
    height: 15vh;
    margin: 12vh auto 0 auto;
    max-width: 85%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 800px) {
      margin: 10vh auto 0 auto;
    }

    @media screen and (max-width: 500px) {
      margin: 8vh auto 0 auto;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
    }

    .forum-logo {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      @media screen and (max-width: 600px) {
        display: none;
      }

      .forum-word {
        color: #ffffff;
        font-family: Gilroy-Bold, sans-serif;
        font-size: 2rem;
        text-transform: uppercase;

        @media screen and (max-width: 800px) {
          margin-bottom: 5px;
        }
      }

      .powered-by-images {
        display: flex;
        align-items: center;
        gap: 20px;

        @media screen and (max-width: 800px) {
          flex-direction: column;
          align-items: center;
          gap: 5px;
        }
      }
    }

    .display {
      display: block;

      @media screen and (max-width: 500px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .account-wrapper {
      display: flex;
      align-items: center;
      gap: 0.8em;

      p {
        color: #ffffff;
        font-family: 'Gilroy-SemiBold', sans-serif;
        font-size: 1rem;
      }

      .log-out {
        cursor: pointer;
      }

      @media screen and (max-width: 800px) {
        img {
          max-width: 20%;
        }
      }

      @media screen and (max-width: 500px) {
        justify-content: space-between;

        img {
          max-width: 15%;
        }
      }
    }

    form {
      box-sizing: border-box;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      min-width: 20%;
      border-bottom: 2px solid #ffffff;
      padding-bottom: 10px;

      @media screen and (max-width: 500px) {
        justify-content: center;
        margin-bottom: 5px;
      }

      input {
        width: 100%;
        outline: none;
        border: none;
        background-color: #f29e06;
        color: #ffffff;
      }

      input::placeholder {
        @media screen and (max-width: 800px) {
          font-size: 1rem;
        }
      }

      img {
        cursor: pointer;

        @media screen and (max-width: 700px) {
          max-width: 10%;
        }
      }

      ::placeholder {
        font-family: Gilroy-Regular, sans-serif;
        font-size: 1.1vw;
        font-weight: 300;
        letter-spacing: 1.1px;
        color: #ffffff;
      }
    }
  }
}

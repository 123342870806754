.wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  .select {
    background: none;
    outline: none;
    border: none;
    cursor: pointer;

    color: #d4d4d4;
    font-size: 18px;
    font-family: Gilroy-Medium, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.89px;
    text-transform: uppercase;

    &:hover {
      color: #f29e06;
    }

    .option {
      appearance: none;
      font-size: 14px;
      letter-spacing: 1px;
      font-weight: 300;
    }
  }
}

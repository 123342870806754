.product-item-wrapper {
  margin: 10%;
}

.product-item--top {
  display: flex;
  gap: 80px;
  margin-bottom: 80px;

  &--desc {
    width: 100%;

    &-title {
      padding-bottom: 15px;
      border-bottom: 1px solid rgba(#7d7d7d, 0.2);
      margin-bottom: 50px;

      color: #040404;
      font-family: Gilroy-Regular, sans-serif;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 3.36px;
    }

    &-desc {
      color: #545454;
      font-family: Gilroy-Medium, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.89px;
    }

    &-categ {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 100px;

      &--categories {
        color: #797979;
        font-family: Gilroy-Regular, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.89px;
      }

      &--categ {
        color: #545454;
        font-family: Gilroy-Medium, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.89px;
      }
    }
  }
}

.product-item--bottom {
  width: 100%;

  &-title {
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(#7d7d7d, 0.2);
    margin-bottom: 20px;

    color: #000;
    font-family: Gilroy-Bold, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2.31px;
    text-transform: uppercase;
  }

  &-desc {
    color: #545454;
    font-family: Gilroy-Medium, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.89px;
  }
}

@media screen and (max-width: 1024px) {
  .product-item-wrapper {
    margin: 15% 5% 5%;
  }

  .product-item--top {
    gap: 50px;
    margin-bottom: 60px;

    &--desc {
      width: 100%;

      &-title {
        padding-bottom: 10px;
        margin-bottom: 30px;

        font-size: 32px * 0.7;
      }

      &-desc {
        font-size: 18px * 0.7;
      }

      &-categ {
        margin-top: 70px;

        &--categories {
          font-size: 18px * 0.7;
        }

        &--categ {
          font-size: 18px * 0.7;
        }
      }
    }
  }

  .product-item--bottom {
    width: 100%;

    &-title {
      padding-bottom: 15px;

      font-family: Gilroy-Bold, sans-serif;
      font-size: 22px * 0.7;
    }

    &-desc {
      font-size: 18px * 0.7;
    }
  }
}

@media screen and (max-width: 800px) {
  .product-item-wrapper {
    margin: 20% 5% 5%;
  }

  .product-item--top {
    gap: 50px;
    margin-bottom: 60px;
    flex-direction: column;
    align-items: center;
  }
}

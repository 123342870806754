.catalog-wrapper {
  margin: 10%;
  display: flex;
  justify-content: space-between;
}

.sidebar-wrapper {
  width: 365px;
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 50px;

  .sidebar-search {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--input {
      background: #f2f2f2;
      border: none;
      outline: none;
      padding: 21px 5px 22px 25px;
      width: 100%;

      color: #8c8c8c;
      font-family: Gilroy-Medium, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.89px;
    }

    &--btn {
      border: none;
      outline: none;
    }
  }

  .sidebar-categories {
    color: #000;
    font-family: Gilroy-Bold, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2.31px;
    text-transform: uppercase;
  }

  .sidebar-categories-list {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &--btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: none;
      border: none;
      outline: none;
      border-bottom: 1px solid rgba(#7d7d7d, 0.2);
      padding-bottom: 15px;

      color: #545454;
      font-family: Gilroy-Medium, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.89px;
      transition: all 0.3s;

      svg {
        path {
          stroke: #545454;
          transition: all 0.3s;
        }
      }

      &:hover {
        color: #f29e06;
        border-bottom: 1px solid rgba(#f29e06, 0.2);

        svg {
          path {
            stroke: #f29e06;
          }
        }
      }

      &:focus {
        color: #f29e06;
        border-bottom: 1px solid rgba(#f29e06, 0.2);

        svg {
          path {
            stroke: #f29e06;
          }
        }
      }
    }
  }
}

.products-wrapper {
  margin-left: 425px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 72px;
  border-left: 1px solid rgba(#7d7d7d, 0.2);
  gap: 50px;

  .not-found-product {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 39px;
    max-width: 711px;
    margin: auto;

    &--title {
      color: #000;
      text-align: center;
      font-family: Gilroy-Bold, sans-serif;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 4.2px;
    }

    &--desc {
      color: #545454;
      font-family: Gilroy-Medium, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.89px;
    }
  }
}

@media screen and (max-width: 1400px) {
  .sidebar-wrapper {
    width: 200px;
    gap: 30px;

    .sidebar-search {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &--input {
        padding: 10px 5px 10px 5px;
        font-size: 18px * 0.7;
      }

      &--btn {
        img {
          width: 41px;
        }
      }
    }

    .sidebar-categories {
      font-size: 22px * 0.7;
    }

    .sidebar-categories-list {
      gap: 10px;

      &--btn {
        padding-bottom: 10px;

        font-size: 18px * 0.7;
      }
    }
  }

  .products-wrapper {
    margin-left: 225px;
    padding-left: 18px;
    gap: 15px;
  }
}

@media screen and (max-width: 1024px) {
  .catalog-wrapper {
    margin: 20% 5% 5%;
    display: flex;
    justify-content: space-between;
  }

  .sidebar-wrapper {
    width: 200px;
    gap: 30px;

    .sidebar-search {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &--input {
        padding: 10px 5px 10px 5px;
        font-size: 18px * 0.7;
      }

      &--btn {
        img {
          width: 41px;
        }
      }
    }

    .sidebar-categories {
      font-size: 22px * 0.7;
    }

    .sidebar-categories-list {
      gap: 10px;

      &--btn {
        padding-bottom: 10px;

        font-size: 18px * 0.7;
      }
    }
  }

  .products-wrapper {
    margin-left: 225px;
    padding-left: 18px;
    gap: 15px;
  }
}

@media screen and (max-width: 500px) {
  .catalog-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .sidebar-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .products-wrapper {
    border-left: none;
    margin-left: 0;
    padding-left: 0;
    margin-top: 50px;
    gap: 10px;
    justify-content: center;
  }
}

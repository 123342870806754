.content-wrapper {
  min-height: 45em;

  .text-wrapper {
    max-width: 65%;
    margin: 10em auto;
    display: flex;
    flex-direction: column;
    font-family: Gilroy, sans-serif;
    text-align: center;

    @media screen and (max-width: 800px) {
      max-width: 90%;
      margin: 5em auto;
    }

    h1 {
      font-size: 2.5em;
      letter-spacing: 0.1em;
      margin-bottom: 0.8em;
      word-wrap: break-word;
      font-family: 'Gilroy-Bold', sans-serif;

      @media screen and (max-width: 800px) {
        font-size: 2em;
      }
    }

    p {
      color: #52525b;
      font-weight: lighter;
      max-width: 60%;
      margin: 0 auto 0.8em auto;
      line-height: 1.7rem;
      font-family: 'Gilroy-Medium', sans-serif;
    }

    span {
      font-family: 'Gilroy-SemiBold', sans-serif;
    }
  }
}
